<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="backToList"
      @schedule="onSchedule"
    />
  </keep-alive>
</template>

<script>
import machineList from '@/views/dashboard/components/machines/machineList';
import machineForm from '@/views/dashboard/components/machines/machineForm';
import machineSchedule from '@/views/dashboard/components/schedule/machineSchedule';
import ProcessService from '@/services/ProcessService.js';
import MachineService from '@/services/MachineService.js';
import MachineMixin from '@/views/dashboard/components/machines/machineMixin';

export default {
  components: {},
  mixins: [MachineMixin],
  data: function () {
    return {
      loading: false,
      pgs: [],
      current: null,
      params: {},
      types: []
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.loadItems();
    this.current = machineList;
    this.params = {};
  },
  methods: {
    onNew() {
      this.current = machineForm;
      this.params = {
        value: {
          id: '',
          name: '',
          type: '',
          setup_time: '00:20',
          efficiency: 0,
          efficiency_unit: '',
          post_delay: '00:10',
          pre_delay: '00:10',
          waste_ranges: [],
          setup_waste: 0,
          processes: []
        },
        pgs: this.pgs,
        types: this.types
      };
    },
    onEdit(item) {
      this.current = machineForm;
      this.params = {
        value: item,
        pgs: this.pgs,
        types: this.types
      };
    },
    onSchedule(item) {
      this.current = machineSchedule;
      this.params = {
        machine: item
      };
    },
    backToList() {
      this.current = machineList;
      this.params = {};
    },
    async loadItems() {
      this.loading = true;
      try {
        let pgs;
        [pgs, this.types] = await Promise.all([
          ProcessService.getProcessGroups(),
          MachineService.getMachineTypes()
        ]);

        this.pgs = pgs.map((pg) => {
          return {
            ...pg,
            tName: this.tProcessGroupName(pg.name),
            processes: pg.processes
          };
        });

        console.log('pgs:', this.pgs);
        console.log('types:', this.types);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
